import '../styles/Home.scss';

import {
  faExternalLinkAlt,
  faPlusSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { HomeProps, HomeState, HomeNavItemProps } from '../@types/Home.d';
import {
  ROUTER_ROUTE_LAYOUTS,
  ROUTER_ROUTE_TEMPLATES,
} from '../constants/constants';
import {
  HOME_ITEM_TITLE_LAYOUTS,
  HOME_ITEM_TITLE_TEMPLATES,
} from '../constants/labels';

const HomeNavItem: React.FC<HomeNavItemProps> = (props: HomeNavItemProps) => {
  const { linkTo, icon, subtitle } = props;
  return (
    <div className="home-item-container">
      <div className="home-item">
        <Link to={linkTo}>
          <FontAwesomeIcon icon={icon} />
          <div className="subtitle">{subtitle}</div>
        </Link>
      </div>
    </div>
  );
};

export default class Home extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    return (
      <div id="home-container">
        <div className="home-items-container">
          <HomeNavItem
            linkTo={ROUTER_ROUTE_TEMPLATES}
            icon={faPlusSquare}
            subtitle={HOME_ITEM_TITLE_TEMPLATES}
          />
          <HomeNavItem
            linkTo={ROUTER_ROUTE_LAYOUTS}
            icon={faExternalLinkAlt}
            subtitle={HOME_ITEM_TITLE_LAYOUTS}
          />
        </div>
      </div>
    );
  }
}
