import { IConfiguration } from '@aurigma/design-editor-iframe/Configuration/ConfigurationInterfaces';
import {
  IProductDefinition,
  ISurfacesFromFolder,
  ISurfacesFromMultipageTemplate,
} from '@aurigma/design-editor-iframe/ObjectModel/ObjectModel';
import { Theme } from 'react-select';
import { Client } from '../@types/Data.d';

export const ROUTER_ROUTE_HOME = '/';
export const ROUTER_ROUTE_LAYOUTS = '/layouts';
export const ROUTER_ROUTE_TEMPLATES = '/templates';
export const ROUTER_ROUTE_EDITOR = '/editor';
export const ROUTER_ROUTE_EDITOR_LAYOUT = `${ROUTER_ROUTE_EDITOR}/layout/`;
export const ROUTER_ROUTE_EDITOR_TEMPLATE = `${ROUTER_ROUTE_EDITOR}/templates/`;
export const ROUTER_ROUTE_EDITOR_LAYOUT_ROUTE = `${ROUTER_ROUTE_EDITOR_LAYOUT}:layoutId`;
export const ROUTER_ROUTE_EDITOR_TEMPLATE_ROUTE = `${ROUTER_ROUTE_EDITOR_TEMPLATE}:templateId`;

export const selectPickerTheme = (theme: Theme): Theme =>
  ({
    borderRadius: 0, // theme.borderRadius,
    colors: {
      ...theme.colors,
      // danger: '#DE350B',
      // dangerLight: '#FFBDAD',
      neutral0: 'rgb(252, 252, 252)', // bg
      neutral5: '#e9ecef', // bg diabled
      neutral10: 'rgb(211, 211, 211)', // border disabled
      // neutral20: 'rgb(0, 0, 0)', // border
      neutral30: process.env.REACT_APP_SCSS_TERTIARY ?? 'rgb(63, 175, 212)', // hover
      // neutral40: 'rgb(83, 164, 198)', // icon hover
      neutral50: 'rgb(116, 116, 116)', // hint color
      neutral60: 'rgb(116, 116, 116)', // icon not focused
      neutral70: 'rgb(116, 116, 116)',
      neutral80: 'rgb(91, 91, 91)', // drop icon press & text
      neutral90: 'rgb(116, 116, 116)',
      primary: process.env.REACT_APP_SCSS_PRIMARY ?? 'rgb(63, 175, 212)', // border focus,
      primary25: process.env.REACT_APP_SCSS_PRIMARY ?? 'rgb(63, 175, 212)', // item hover
      primary50: process.env.REACT_APP_SCSS_TERTIARY ?? 'rgb(83, 164, 198)', // item press
      // primary75: 'pink',
      neutral40: 'rgb(255, 255, 255)', // item press
    },
    spacing: theme.spacing,
  } as Theme);

export const CUSTOMER_CANVAS_CONFIG = (client: Client): IConfiguration =>
  ({
    initialMode: 'SimpleOnly',
    widgets: {
      ObjectInspector: {
        isHidden: true,
      },
    },
    canvas: {
      shadowEnabled: true,
    },
    watermark: {
      image: {
        name: 'FP-watermark_alt2.png',
        repeat: true,
        opacity: 0.2,
      },
      visibility: {
        proof: true,
      },
    },
    userId: `${client.id}`,
  } as IConfiguration);

export const ELN_IMAGE_NAME = 'ELN Fahrzeug';
export const ELN_IMAGE_TYPE = 'PlaceholderItem';

export const ELN_ELEMENT_TYPE = 'BoundedTextItem';
export const ELN_ELEMENT_CONSUMPTION = 'Kraftstoffverbrauch';
export const ELN_ELEMENT_EMISSION = 'CO2-Emmission';

export const DUMMY_PRINT_TEMPLATES = [
  {
    id: 1,
    name: 'ELN (A4, 2-Seiter)',
    product: {
      surfaces: { designFolder: 'ELN/A4_2_Seiter' } as ISurfacesFromFolder,
    } as IProductDefinition,
  },
  {
    id: 2,
    name: 'ELN (A4, 4-Seiter)',
    product: {
      surfaces: {
        designFolder: 'ELN/A4_4_Seiter',
      } as ISurfacesFromFolder,
    } as IProductDefinition,
  },
  {
    id: 3,
    name: 'Template 1',
    product: {
      surfaces: {
        designFolder: 'samples',
      } as ISurfacesFromFolder,
    } as IProductDefinition,
  },
  {
    id: 4,
    name: 'Template 2',
    product: {
      surfaces: {
        designFolder: 'ELN/test01',
      } as ISurfacesFromFolder,
    } as IProductDefinition,
  },
  {
    id: 5,
    name: 'Template 3',
    product: {
      surfaces: {
        designFolder: 'ELN/test-page',
      } as ISurfacesFromFolder,
    } as IProductDefinition,
  },
  {
    id: 6,
    name: 'ELN-Demo Schroers (A4, 4-Seiter)',
    product: {
      surfaces: {
        file: 'ELN/A4_4_Seiter/ELN_Demo_Schroers',
        names: ['Seite 1', 'Seite 2'],
      } as ISurfacesFromMultipageTemplate,
    } as IProductDefinition,
  },
];
