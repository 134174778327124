import '../../styles/LayoutDesign.scss';

import * as React from 'react';
import { withRouter } from 'react-router-dom';

import Select from 'react-select';
import JSZip from 'jszip';
import moment from 'moment';
import { saveAs } from 'file-saver';

import {
  downloadLayoutProofImages,
  getClientLayout,
  getPrintTemplate,
  postClientLayout,
  putClientLayout,
} from '../../util/api';
import {
  REQUEST_IDENTIFIER_GET_CLIENT_LAYOUT,
  REQUEST_IDENTIFIER_GET_PRINT_TEMPLATE,
  REQUEST_IDENTIFIER_PUT_CLIENT_LAYOUTS,
  REQUEST_IDENTIFIER_DOWNLOAD_PREVIEW_IMAGES,
} from '../../constants/network';
import {
  BUTTON_DOWNLOAD,
  BUTTON_PREVIEW,
  BUTTON_PULISH,
  BUTTON_SAVE,
  BUTTON_SAVE_AS,
  CLIENT_SELECT_PLACEHOLDER,
} from '../../constants/labels';

import CustomerCanvasContainer from './CustomerCanvasContainer';
import SaveLayoutModal from '../Modal/SaveLayoutModal';
import PreviewLayoutModal from '../Modal/PreviewLayoutModal';

import {
  LayoutDesignContainerProps,
  LayoutDesignContainerState,
} from '../../@types/LayoutDesign.d';
import {
  ClientLayout,
  ClientLocation,
  PrintTemplate,
} from '../../@types/Data.d';
import { selectPickerTheme } from '../../constants/constants';

class LayoutDesignContainer extends React.Component<
  LayoutDesignContainerProps,
  LayoutDesignContainerState
> {
  customerCanvasRef = React.createRef<CustomerCanvasContainer>();

  saveLayoutNameModalRef = React.createRef<SaveLayoutModal>();

  previewLayoutNameModalRef = React.createRef<PreviewLayoutModal>();

  constructor(props: LayoutDesignContainerProps) {
    super(props);

    const { client } = this.props;

    this.state = {
      showSaveLayoutModal: false,
      showPreviewLayoutModal: false,
      clientLocation: client?.clientLocations[0],
    };

    this.getData = this.getData.bind(this);
    this.showSaveLayoutModal = this.showSaveLayoutModal.bind(this);
    this.showPreviewLayoutModal = this.showPreviewLayoutModal.bind(this);
    this.saveLayout = this.saveLayout.bind(this);
    this.publishLayout = this.publishLayout.bind(this);

    this.fetchLayout = this.fetchLayout.bind(this);
    this.fetchTemplate = this.fetchTemplate.bind(this);

    this.onClickSave = this.onClickSave.bind(this);
    this.onClickSaveAs = this.onClickSaveAs.bind(this);
    this.onClickPublish = this.onClickPublish.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
    this.onClickPreview = this.onClickPreview.bind(this);
    this.onChangeClientLocation = this.onChangeClientLocation.bind(this);
  }

  componentDidMount(): void {
    this.getData();
  }

  componentDidUpdate(prevProps: LayoutDesignContainerProps): void {
    const { match, client } = this.props;

    if (
      prevProps.match.path !== match.path ||
      prevProps.client?.id !== client?.id
    )
      this.getData();
  }

  onClickSave(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();

    const { layout } = this.state;

    if (layout) this.saveLayout(false);
    else this.showSaveLayoutModal(true);
  }

  onClickSaveAs(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();

    const { current } = this.saveLayoutNameModalRef;

    if (current === null) return;

    current.setSaveCopy(true);
    this.showSaveLayoutModal(true);
  }

  async onClickPublish(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const { current: customerCanvas } = this.customerCanvasRef;
    const { current: previewModal } = this.previewLayoutNameModalRef;

    if (customerCanvas === null || previewModal === null) return;

    const finsishResult = await customerCanvas.finishDesign();

    if (!finsishResult) return;

    const { layout } = this.state;

    if (!layout) return;

    const { documentPath } = finsishResult;

    this.setState({
      layout: { ...layout, ...{ documentPath } } as ClientLayout,
    });

    previewModal.setProofImages(finsishResult.proofImages);
    previewModal.setPublish(true);

    this.showPreviewLayoutModal(true);
  }

  async onClickDownload(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const { current } = this.customerCanvasRef;

    if (current === null) return;

    const previewImages = await current.getPreview();

    if (previewImages) this.downloadPreviewImages(previewImages);
  }

  async onClickPreview(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const { current: customerCanvas } = this.customerCanvasRef;
    const { current: previewModal } = this.previewLayoutNameModalRef;

    if (customerCanvas === null || previewModal === null) return;

    const previewImages = await customerCanvas.getPreview();

    if (!previewImages) return;

    previewModal.setProofImages(previewImages);
    previewModal.setPublish(false);

    this.showPreviewLayoutModal(true);
  }

  onChangeClientLocation(clientLocation: ClientLocation | null): void {
    if (clientLocation && clientLocation !== null)
      this.setState({ clientLocation });
  }

  getData(): void {
    const { type } = this.props;

    if (type === 'LAYOUT') this.fetchLayout();
    if (type === 'TEMPLATE') this.fetchTemplate();
  }

  async fetchLayout(): Promise<void> {
    const { match, client, showLoading } = this.props;
    const { layoutId } = match.params;

    if (!layoutId || !client) return;

    showLoading(true, REQUEST_IDENTIFIER_GET_CLIENT_LAYOUT);

    const layout = (await getClientLayout(
      client.uuid,
      layoutId
    )) as ClientLayout;

    if (layout) this.setState({ layout });

    showLoading(false, REQUEST_IDENTIFIER_GET_CLIENT_LAYOUT);
  }

  async fetchTemplate(): Promise<void> {
    const { match, client, showLoading } = this.props;
    const { templateId } = match.params;

    if (!templateId || !client) return;

    showLoading(true, REQUEST_IDENTIFIER_GET_PRINT_TEMPLATE);

    const template = (await getPrintTemplate(
      client.uuid,
      templateId
    )) as PrintTemplate;

    if (template) this.setState({ template });

    showLoading(false, REQUEST_IDENTIFIER_GET_PRINT_TEMPLATE);
  }

  showSaveLayoutModal(showSaveLayoutModal: boolean): void {
    this.setState({ showSaveLayoutModal });
  }

  showPreviewLayoutModal(showPreviewLayoutModal: boolean): void {
    this.setState({ showPreviewLayoutModal });
  }

  async downloadPreviewImages(imageURLs: string[]): Promise<void> {
    const { client, showLoading } = this.props;

    showLoading(true, REQUEST_IDENTIFIER_DOWNLOAD_PREVIEW_IMAGES);

    const images = await downloadLayoutProofImages(imageURLs);

    const zip = new JSZip();

    const imageFolder = zip.folder(
      `layout-${client?.name ?? ''}-${moment().format('DDMMYY-HH-mm-ss')}`
    );

    if (imageFolder === null) return;

    images.forEach((img, index) =>
      imageFolder.file(`layout-${client?.name}-page${index + 1}.jpg`, img)
    );

    zip
      .generateAsync({ type: 'blob' })
      .then((content) =>
        saveAs(
          content,
          `layout-${client?.name ?? ''}-${moment().format(
            'DDMMYY-HH-mm-ss'
          )}.zip`
        )
      )
      .finally(() =>
        showLoading(false, REQUEST_IDENTIFIER_DOWNLOAD_PREVIEW_IMAGES)
      );
  }

  async saveLayout(saveCopy: boolean, name?: string): Promise<void> {
    const { layout } = this.state;
    const { client, showLoading } = this.props;

    const { current } = this.customerCanvasRef;

    if (current === null || !client) return;

    showLoading(true, REQUEST_IDENTIFIER_PUT_CLIENT_LAYOUTS);

    await current.insertDataFromImages();

    const saveResult = await current.save();

    if (!saveResult) {
      showLoading(false, REQUEST_IDENTIFIER_PUT_CLIENT_LAYOUTS);

      return;
    }

    let layoutNew;

    if (!layout || saveCopy) {
      layoutNew = (await postClientLayout(client.uuid, {
        name,
        url: saveResult.returnToEditUrl,
        stateFileId: saveResult.stateId,
        state: 'EDIT',
        docuemtnPath: '',
      })) as ClientLayout;
    } else {
      layoutNew = (await putClientLayout(client.uuid, {
        ...layout,
        ...{ name: name ?? layout?.name },
      })) as ClientLayout;
    }

    if (!(layoutNew as ClientLayout).stateFileId) {
      showLoading(false, REQUEST_IDENTIFIER_PUT_CLIENT_LAYOUTS);

      return;
    }

    this.setState({ layout: layoutNew }, () =>
      showLoading(false, REQUEST_IDENTIFIER_PUT_CLIENT_LAYOUTS)
    );
  }

  async publishLayout(): Promise<void> {
    const { client, sendFinishAction, showLoading } = this.props;
    const { layout } = this.state;

    if (!layout || !client || !sendFinishAction) return;

    showLoading(false, REQUEST_IDENTIFIER_PUT_CLIENT_LAYOUTS);

    const publishResult = await putClientLayout(client.uuid, {
      ...layout,
      ...{ state: 'APPROVED' },
    });

    if (!Number.isNaN(+publishResult)) {
      showLoading(false, REQUEST_IDENTIFIER_PUT_CLIENT_LAYOUTS);
      return;
    }

    sendFinishAction();

    showLoading(false, REQUEST_IDENTIFIER_PUT_CLIENT_LAYOUTS);
  }

  render(): JSX.Element {
    const { client, type, showLoading } = this.props;
    const {
      layout,
      template,
      showSaveLayoutModal,
      showPreviewLayoutModal,
      clientLocation,
    } = this.state;

    return (
      <div className="layout-design-container">
        <SaveLayoutModal
          ref={this.saveLayoutNameModalRef}
          show={showSaveLayoutModal}
          showModal={this.showSaveLayoutModal}
          saveLayout={this.saveLayout}
        />
        <PreviewLayoutModal
          ref={this.previewLayoutNameModalRef}
          show={showPreviewLayoutModal}
          showModal={this.showPreviewLayoutModal}
          publishLayout={this.publishLayout}
        />
        <CustomerCanvasContainer
          ref={this.customerCanvasRef}
          client={client}
          type={type}
          layout={layout ?? template}
          showLoading={showLoading}
          clientLocation={clientLocation}
        />
        <div className="layout-buttons-container">
          <div className="button-container">
            <Select
              blurInputOnSelect
              isSearchable
              hasVaue
              placeholder={CLIENT_SELECT_PLACEHOLDER}
              options={client?.clientLocations}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => `${option.id}`}
              value={clientLocation}
              onChange={this.onChangeClientLocation}
              theme={(theme) => selectPickerTheme(theme)}
            />
          </div>
          <div className="button-container">
            <button type="button" onClick={this.onClickSave}>
              {BUTTON_SAVE}
            </button>
          </div>
          {layout && (
            <>
              <div className="button-container">
                <button type="button" onClick={this.onClickSaveAs}>
                  {BUTTON_SAVE_AS}
                </button>
              </div>
              <div className="button-container">
                <button type="button" onClick={this.onClickPublish}>
                  {BUTTON_PULISH}
                </button>
              </div>
            </>
          )}
          <div className="button-container">
            <button type="button" onClick={this.onClickPreview}>
              {BUTTON_PREVIEW}
            </button>
          </div>
          <div className="button-container">
            <button type="button" onClick={this.onClickDownload}>
              {BUTTON_DOWNLOAD}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LayoutDesignContainer);
