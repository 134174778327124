export type NavigationMessageType = '/' | '/layout' | '/templates';
export type MessagePayload = NavigationMessageType | undefined;

export enum MessageType {
  NAVIGATE = 'navigate',
  FINISH_DESIGN = 'finish_design',
}

export interface Message {
  payload: MessagePayload;
  type: MessageTypes;
}
