import {
  ContentItem,
  ImageItem,
} from '@aurigma/design-atoms-model/Product/Items';
import moment from 'moment';
import { ClientLocation, CustomerCanvasClientData } from '../@types/Data.d';

export const strCmp = (a: string, b: string, desc?: boolean): number => {
  if (a.toLowerCase() > b.toLowerCase()) return desc ? -1 : 1;
  if (a.toLowerCase() < b.toLowerCase()) return desc ? 1 : -1;
  return 0;
};

export const extractELNValue = (
  displayName: string
): { emission: string; consumption: string } => {
  const [, values] = displayName.split('%');

  // eslint-disable-next-line prefer-const
  let [consumption, emission] = values.split('_');

  emission = emission.substring(0, emission.lastIndexOf('.'));

  return { emission, consumption };
};

export const extractELNValues = (
  displayNames: string[]
): { emissions: number[]; consumptions: number[] } => {
  let consumptions = [] as number[];
  let emissions = [] as number[];

  displayNames.forEach((dispalyName) => {
    const { emission, consumption } = extractELNValue(dispalyName);

    if (!Number.isNaN(+consumption))
      consumptions = [...consumptions, ...[+consumption]];

    if (!Number.isNaN(+emission)) emissions = [...emissions, ...[+emission]];
  });

  return { emissions, consumptions };
};

export const extractFileName = (
  imageItem: ContentItem | null | undefined
): string => {
  if (!imageItem || imageItem === null) return '';

  return (
    (imageItem as ImageItem).displayName ??
    imageItem.tags?.sourceData?.name ??
    ''
  );
};

export const extractCustomerCanvasUserInfo = (
  clientLocation: ClientLocation
): CustomerCanvasClientData => {
  const {
    addressName,
    postcode,
    city,
    street,
    housenumber,
    fax,
    web,
    phone,
    email,
  } = clientLocation;

  // eslint-disable-next-line camelcase
  const Monat_Jahr = moment().format('MM/YY');

  return {
    Firmenname: addressName,
    PLZ: postcode,
    Ort: city,
    Strasse: `${street} ${housenumber}`,
    Telefon: phone ?? '',
    Fax: fax ?? '',
    Website: web ?? '',
    EMail: email ?? '',
    Monat_Jahr,
  };
};
