import { Message, MessagePayload, MessageType } from '../@types/MessageTypes.d';

export const sendMessage = (
  type: MessageType,
  payload: MessagePayload,
  parentOrigin: string
): void => {
  const pPayload = { type, payload } as Message;

  window.parent.postMessage(pPayload, parentOrigin);
};

export const sendFinishAction = (parentOrigin: string): void =>
  sendMessage(MessageType.FINISH_DESIGN, undefined, parentOrigin);
