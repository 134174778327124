import axios from 'axios';

import config from '../config';

import {
  extractClientLayouts,
  extractClientLayout,
  extractClient,
  extractClientPrintTemplates,
  extractClientPrintTemplate,
  extractClientLocations,
} from './responseUtil';

import {
  CUSTOMER_CANVAS_TEMPLATE_PREVIEW,
  CUSTOMER_CANVAS_TEMPLATE_FILES,
  PATH_CLIENT_GET,
  PATH_CLIENT_LAYOUTS_GET,
  PATH_CLIENT_LAYOUT_DELETE,
  PATH_CLIENT_LAYOUT_GET,
  PATH_CLIENT_LAYOUT_POST,
  PATH_CLIENT_LAYOUT_PUT,
  PATH_CLIENT_PRINT_LAYOUTS,
  PATH_CLIENT_PRINT_LAYOUT,
  PATH_CLIENT_LOCATIONS_GET,
} from '../constants/network';

import {
  Client,
  ClientLayout,
  ClientLocation,
  PrintTemplate,
  StateFileState,
} from '../@types/Data.d';

export const axiosClient = axios.create({
  baseURL: config.apiUrl,
  responseType: 'json',
});

const headers = {
  'X-API-Key-Token': process.env.REACT_APP_FRA_API_KEY,
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export const printError = (error: any): string => {
  // eslint-disable-next-line no-console
  if (config.printToConsole) console.log(error);
  return (error.response || error.request).status;
};

export const getClient = (clientUUID: string): Promise<string | Client> =>
  axiosClient
    .get(PATH_CLIENT_GET(clientUUID), {
      headers,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClient(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

export const getClientTemplates = (
  clientUUID: string
): Promise<string | PrintTemplate[]> =>
  axiosClient
    .get(PATH_CLIENT_PRINT_LAYOUTS(clientUUID), {
      headers,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientPrintTemplates(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

export const getPrintTemplate = (
  clientUUID: string,
  printTemplateId: number
): Promise<string | PrintTemplate> =>
  axiosClient
    .get(PATH_CLIENT_PRINT_LAYOUT(clientUUID, printTemplateId), {
      headers,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientPrintTemplate(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

export const getClientLayouts = (
  clientUUID: string,
  state: StateFileState
): Promise<string | ClientLayout[]> =>
  axiosClient
    .get(PATH_CLIENT_LAYOUTS_GET(clientUUID), {
      params: { state },
      headers,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientLayouts(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

export const getClientLayout = (
  clientUUID: string,
  layoutId: number
): Promise<string | ClientLayout> =>
  axiosClient
    .get(PATH_CLIENT_LAYOUT_GET(clientUUID, layoutId), {
      headers,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientLayout(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

export const postClientLayout = (
  clientUUID: string,
  layout: any
): Promise<string | ClientLayout> =>
  axiosClient
    .post(PATH_CLIENT_LAYOUT_POST(clientUUID), layout, {
      headers,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientLayout(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

export const putClientLayout = (
  clientUUID: string,
  layout: any
): Promise<string | ClientLayout> =>
  axiosClient
    .put(PATH_CLIENT_LAYOUT_PUT(clientUUID), layout, {
      headers,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => extractClientLayout(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

export const deleteClientLayout = (
  clientUUID: string,
  layoutId: number
): Promise<boolean> =>
  axiosClient
    .delete(PATH_CLIENT_LAYOUT_DELETE(clientUUID, layoutId), {
      headers,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      () => true,
      (error) => {
        printError(error);
        return false;
      }
    )
    .catch((error) => {
      printError(error);
      return false;
    });

export const downloadLayoutProofImages = (
  imageURLs: string[]
): Promise<Blob[]> =>
  axios
    .all(
      imageURLs.map((imageURL) =>
        axios.get(imageURL, { responseType: 'arraybuffer' })
      )
    )
    .then((responses) =>
      responses.map(
        (response) => new Blob([response.data], { type: 'image/jpeg' })
      )
    );

export const getTemplatePreviewURL = async (
  subfolder: string
): Promise<string> => {
  const fileListParams = { subfolder };

  const firstFile = await axios
    .get(CUSTOMER_CANVAS_TEMPLATE_FILES, {
      params: fileListParams,
      validateStatus: (status: number): boolean => status < 300,
    })
    .then(
      (response) => response.data[0] as string,
      () => undefined
    );

  if (!firstFile) return CUSTOMER_CANVAS_TEMPLATE_PREVIEW(subfolder);

  return CUSTOMER_CANVAS_TEMPLATE_PREVIEW(`${subfolder}/${firstFile}`);
};

export const getClientLocations = (
  clientUUID: string
): Promise<string | ClientLocation[]> =>
  axiosClient
    .get(PATH_CLIENT_LOCATIONS_GET(clientUUID), {
      headers,
      validateStatus: (status: number) => status < 300,
    })
    .then(
      (response) => extractClientLocations(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));
