import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {
  PARAM_CLIENT_ID,
  PARAM_INIT_TARGET,
  PARAM_PARENTORIGIN,
} from './constants/network';

const params = window.location.toString().split('?');

let clientUUID: string | undefined;
let parentOrigin: string | undefined;
let initTarget: string | undefined;

if (params.length > 1)
  params[1].split('&').forEach((param) => {
    const [key, value] = param.split('=');

    switch (key.toLowerCase()) {
      case PARAM_CLIENT_ID:
        clientUUID = value;
        break;
      case PARAM_PARENTORIGIN:
        parentOrigin = value;
        break;
      case PARAM_INIT_TARGET:
        initTarget = value;
        break;
      default:
    }
  });

ReactDOM.render(
  <Router>
    <App
      clientUUID={clientUUID}
      parentOrigin={parentOrigin}
      initTarget={initTarget}
    />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
