import {
  Client,
  ClientLayout,
  ClientLocation,
  PrintTemplate,
} from '../@types/Data.d';

export const extractClient = ({ id, name, planLayout, uuid }: any): Client =>
  ({
    id,
    uuid,
    name,
    planLayout: true,
  } as Client);

export const extractClientLayout = (responseLayout: any): ClientLayout => {
  const { id, name, url, state, stateFileId, documentPath } = responseLayout;

  return {
    id,
    name,
    url,
    state,
    stateFileId,
    documentPath: documentPath ?? '',
  } as ClientLayout;
};

export const extractClientLayouts = (responseLayouts: any[]): ClientLayout[] =>
  responseLayouts.map((responseLayout) => extractClientLayout(responseLayout));

export const extractClientPrintTemplate = (
  responsePrintTemplate: any
): PrintTemplate => {
  const { file, id, name } = responsePrintTemplate;

  return {
    id,
    name,
    product: {
      surfaces: {
        file,
      },
    },
  } as PrintTemplate;
};

export const extractClientPrintTemplates = (
  responsePrintTemplates: any[]
): PrintTemplate[] =>
  responsePrintTemplates.map((responsePrintTemplate) =>
    extractClientPrintTemplate(responsePrintTemplate)
  );

export const extractClientLocation = ({
  addressName,
  city,
  housenumber,
  id,
  name,
  number,
  postcode,
  street,
  billingDefault,
  email,
  phone,
  web,
  fax,
}: any): ClientLocation =>
  ({
    addressName,
    city,
    housenumber,
    id,
    name,
    number,
    postcode,
    street,
    billingDefault: billingDefault ?? false,
    email,
    phone,
    web,
    fax,
  } as ClientLocation);

export const extractClientLocations = (
  responseClientLocations: any
): ClientLocation[] =>
  responseClientLocations
    .map((responseClientLocation: any) =>
      extractClientLocation(responseClientLocation)
    )
    .sort((a: ClientLocation, b: ClientLocation) => {
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
      return 0;
    });
